<template>
  <v-card style="margin: 20px; margin-left: 35px; padding: 20px; text-align:left;">
    <v-breadcrumbs style="margin-left: 15px;">
      <v-breadcrumbs-item to="/settings" color="primary">{{ $t("scorecards.settings") }}</v-breadcrumbs-item>
      > <v-breadcrumbs-item to="/:tenant/settings/conversationtypes" color="primary">{{ $t("scorecards.coaching")
        }}</v-breadcrumbs-item> >
      <v-breadcrumbs-item>{{ $t("scorecards.title") }}</v-breadcrumbs-item>
    </v-breadcrumbs>
    <h1 v-if="selectedFieldName"><span style="color: black; text-align:left;margin-left:15px;">{{ $t("scorecards.for")
        }}</span> <span style="color: #5932EA"> {{ selectedFieldName }}</span></h1>
    <v-alert type="info" color="backgroundsecondary" style="text-align: left; margin-left: 15px; margin-bottom: 30px;">
      {{ $t("scorecards.description") }}
    </v-alert>
    <h3 style="margin-left: 15px; max-width: 300px; text-align: left; padding-bottom: 20px">{{ $t("scorecards.title") }}
    </h3>
    <div style="align-items: left; margin-left: 15px; align-self:left; align-text: left; text-align: left;">
      <v-btn color="primary" @click="addScorecard" prepend-icon="mdi-plus"
        style="margin-left: 0; margin-bottom:15px; align-items:left; align-text:left; text-align: left;">{{
          $t("scorecards.create") }}</v-btn>
    </div>
    <h3 style="margin-left: 15px; max-width: 300px; text-align: left; padding-bottom: 20px">Vorlagen</h3>


    <v-simple-table hover="true" fixed-header style="margin-left: 15px; width:95%; table-layout: auto;">
      <template v-slot:default>
        <thead style="width: 450px; padding:15px;">
          <tr>
            <th style="padding:15px;">{{ $t("scorecards.name") }}</th>
            <th style="padding:15px;">{{ $t("scorecards.created_at") }}</th>
            <th style="padding:15px;">{{ $t("scorecards.action") }}</th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="scorecard in scorecards" :key="scorecard.id">
            <td>{{ scorecard.name }}</td>
            <td style="margin:10px">{{ new Date(scorecard.created_at).toLocaleDateString('de-DE') }}</td>
            <!-- <td style="margin:10px">{{ scorecard.user }}</td> -->
            <td style="margin:15px; padding:10px;">
              <v-btn color="#9197B3" margin:15px class="mr-3" @click="selectScorecard(scorecard)">{{
                $t("scorecards.select") }}</v-btn>
              <v-btn color="#9197B3" margin:15px @click="editScorecard(scorecard)">{{ $t("scorecards.adapt") }}</v-btn>
              <v-icon @click="confirmDelete(scorecard)" class="delete-icon">
                mdi-delete-outline
              </v-icon>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </v-card>
  <v-dialog v-model="showDeleteDialog" persistent max-width="290">
    <v-card>
      <v-card-title class="headline">{{ $t("scorecards.deleteScorecard") }}</v-card-title>
      <v-card-text>{{ $t("scorecards.deleteScorecardConfirm") }}</v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="blue darken-1" text @click="showDeleteDialog = false">{{ $t("scorecards.cancel") }}</v-btn>
        <v-btn color="red darken-1" text @click="deleteScorecard">{{ $t("scorecards.delete") }}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>


<script setup>
import { onMounted, ref } from 'vue' // excl ref
import { supabase, userState } from "../supabase";
import { useRouter } from 'vue-router'
import { useRoute } from 'vue-router';

const scorecards = ref([])
const router = useRouter()
const route = useRoute();
const selectedFieldName = ref(route.query.selectedFieldName);
const tenant = route.params.tenant; // get the tenant from the route parameters
const showDeleteDialog = ref(false);
const scorecardToDelete = ref(null);

const selectScorecard = async (scorecard) => {
  const { error } = await supabase
    .from('conversationtypes')
    .update({ associated_scorecard: scorecard.id })
    .eq('name', selectedFieldName.value);
  if (error) {
    console.error('Error updating conversation type:', error);
  } else {
    router.push({ name: 'conversation types' });
  }
};

const editScorecard = (scorecard) => {
  if (scorecards.value.some(sc => sc.id === scorecard.id)) {
    router.push(`/${tenant}/scorecards/${scorecard.id}`);
  } else {
    console.error('Cannot edit a deleted or non-existent scorecard');
  }
};

const confirmDelete = (scorecard) => {
  scorecardToDelete.value = scorecard;
  showDeleteDialog.value = true;
  router.push(`/${tenant}/scorecards/`);
};

const deleteScorecard = async () => {
  if (!scorecardToDelete.value) return;
  const { error } = await supabase
    .from('scorecards')
    .delete()
    .eq('id', scorecardToDelete.value.id);

  if (error) {
    console.error('Error deleting scorecard:', error);
  } else {
    scorecards.value = scorecards.value.filter((sc) => sc.id !== scorecardToDelete.value.id);
    // Reset the selectedFieldName if it matches the deleted scorecard
    if (selectedFieldName.value === scorecardToDelete.value.name) {
      selectedFieldName.value = null;
    }
    showDeleteDialog.value = false;
    // After deletion, navigate to the scorecards list
    router.push(`/${tenant}/scorecards`);
  }
};

// eslint-disable-next-line no-unused-vars
const channel = supabase
  .channel('custom-all-channel')
  .on(
    'postgres_changes',
    { event: '*', schema: 'public', table: 'scorecards', condition: `organisation = '${userState.currentUser.organisationId}'` },
    (payload) => {
      console.log(payload);
      // Check if the payload contains a new scorecard before navigating
      if (payload.new && payload.new.id) {
        router.push(`/${tenant}/scorecards/${payload.new.id}`);
      } else {
        console.log('No new scorecard to navigate to.');
      }
    }
  )
  .subscribe()

const addScorecard = async () => {
  // gets current user
  const user = userState.currentUser;
  if (!user) throw new Error('User not found')

  // This is how you filter an array column to match a specific uuid
  const { data: organisations, error: orgError } = await supabase
    .from('organisations')
    .select('id', 'name')
    .contains('user_id', [user.id])
  if (orgError) throw orgError
  const organisationId = organisations[0].id;
  // tenant = organisations[0].name;

  // eslint-disable-next-line no-unused-vars
  const { data: newScorecard, error } = await supabase
    .from('scorecards')
    .insert([{ name: '', organisation: organisationId }])
    .single()
    .select()
  if (error) throw error

  const boilerplateContent = [
    {
      "id": newScorecard.id,
      "chapters": [
        {
          "name": "FirstChapter",
          "topics": [{ "id": '', "name": "test 1", "editing": false }],
          "adding": true,
          "editing": false
        }
      ]
    }
  ];

  // eslint-disable-next-line no-unused-vars
  const { data: UpdatedScorecard, updateError } = await supabase
    .from('scorecards')
    .update({ contents: boilerplateContent })
    .eq('id', newScorecard.id)
  if (updateError) throw updateError
}

onMounted(async () => {
  try {
    // gets current user
    const user = userState.currentUser;
    if (!user) throw new Error('User not found')

    // This is how you filter an array column to match a specific uuid
    const { data: organisations, error: orgError } = await supabase
      .from('organisations')
      .select('id')
      .contains('associated_user_ids', [user.id])
    if (orgError) throw orgError
    const organisationIds = organisations.map(org => org.id);

    // fetches the advisors for the specific org id
    const { data: scorecardsData, error: advError } = await supabase
      .from('scorecards')
      .select('*')
      .in('organisation', organisationIds);

    if (advError) throw advError
    scorecards.value = scorecardsData

  } catch (error) {
    console.error('Error: ', error.message)
  }
})
</script>

<style scoped>
.delete-icon {
  color: #9197B3;
}

.delete-icon:hover {
  color: #EC5B84;
}
</style>